import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../const/urls';
import { Sort } from '@angular/material/sort';
import { ProductRatingListDTO, VeganMapLocationRatingListDTO } from '../dto/RatingDTO';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  constructor(private client: HttpClient) {}

  getLocationRatings(page: number, size: number, sort: Sort): Observable<VeganMapLocationRatingListDTO> {
    const href = serverUrl + '/api/map/locations/ratings';
    const requestUrl = `${href}?sort=${sort.active},${sort.direction}&page=${page}&size=${size}`;

    return this.client.get<VeganMapLocationRatingListDTO>(requestUrl);
  }

  getProductRatings(page: number, size: number, sort: Sort): Observable<ProductRatingListDTO> {
    const href = serverUrl + '/api/products/ratings';
    const requestUrl = `${href}?sort=${sort.active},${sort.direction}&page=${page}&size=${size}`;

    return this.client.get<ProductRatingListDTO>(requestUrl);
  }

  getLocationRatingsFromUser(userId: string): Observable<VeganMapLocationRatingListDTO> {
    const href = serverUrl + '/api/map/locations/ratings';
    const requestUrl = `${href}?userId=${userId}`;

    return this.client.get<VeganMapLocationRatingListDTO>(requestUrl);
  }

  getProductRatingsFromUser(userId: string): Observable<ProductRatingListDTO> {
    const href = serverUrl + '/api/products/ratings';
    const requestUrl = `${href}?userId=${userId}`;

    return this.client.get<ProductRatingListDTO>(requestUrl);
  }
}
