import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { Post } from '../../dto/Post';
import { PostService } from '../../services/post.service';
import { CreatePost } from '../../dto/CreatePost';
import { FileDTO } from '../../dto/FileDTO';
import { FileService } from '../../services/file.service';
import { CommentDTO } from '../../dto/CommentDTO';
import { UserService } from '../../services/user.service';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';

export interface DialogData {
  post: Post;
  editMode: boolean;
}

@Component({
  selector: 'post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.css']
})
export class PostDialogComponent implements OnInit {
  editMode = false;
  availebleImages: FileDTO[] = [];
  comments: CommentDTO[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private postService: PostService,
    private fileService: FileService,
    private userService: UserService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.editMode = this.data.editMode;
    this.getAvalableImages();

    this.postService.getCommentsForPost(this.data.post.id)
      .subscribe(comments => this.comments = comments);
  }

  getAvalableImages() {
    this.fileService.getUploadedFiles().subscribe(res => {

      this.availebleImages = res.map(file => {
        file.url = file.url.replace('http', 'https');
        return file;
      });
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  editDialog() {
    this.editMode = !this.editMode;
  }

  saveDialog() {
    const releaseDate = new Date(this.data.post.releaseDate);
    releaseDate.setHours(5);

    const newPost: CreatePost = {
      title: this.data.post.title,
      subtitle: this.data.post.subtitle,
      description: this.data.post.description,
      imageUrl: this.data.post.imageUrl.replace('/thumbnail', ''),
      link: this.data.post.link,
      releaseDate: releaseDate,
      creationDate: new Date(this.data.post.releaseDate),
      viewAmount: this.data.post.viewAmount,
      language: this.data.post.language
    };
    if (this.data.post.id) {
      this.postService.update(newPost, this.data.post.id).subscribe((res) => {
        this.editMode = !this.editMode;
        this.closeDialog();
      });
    } else {
      newPost.viewAmount = 0;
      this.postService.create(newPost).subscribe((res) => {
        this.editMode = !this.editMode;
        this.closeDialog();
      });
    }

  }

  getLanguageIcon(language: string) {
    switch (language) {
      case 'DE':
        return '/assets/img/germany.png';
      case 'EN':
        return '/assets/img/united-kingdom.png';
      default:
        return '/assets/img/germany.png';
    }
  }

  postOnTwitter() {
    this.postService.postOnTwitter(this.data.post.id)
      .subscribe(res => console.log(res));
  }

  postOnInstagram() {
    this.postService.postOnInstagram(this.data.post.id)
      .subscribe(res => console.log(res));
  }

  openUser(comment: CommentDTO) {
    this.userService.getUserById(comment.userId)
      .subscribe(user => {
        const dialogRef = this.dialog.open(UserDialogComponent, {
          data: {
            user: user
          },
          width: window.innerWidth < 991 ? '100vw' : '50vw',
        });
    
        dialogRef.afterClosed().subscribe(closed => {
        })
      })
  }
}
