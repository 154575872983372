import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { VeganMapLocationRatingDTO } from '../../dto/RatingDTO';

import * as L from 'leaflet';

export interface LocationDialogData {
  location: VeganMapLocationRatingDTO;
}

@Component({
  selector: 'location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.css']
})
export class LocationDialogComponent implements OnInit, AfterViewInit {
  private map!: L.Map
  location: VeganMapLocationRatingDTO;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LocationDialogData,
    public dialog: MatDialog,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.location = this.data.location;
  }

  ngAfterViewInit(): void {
    this.initializeMap();
    this.addMarkers();
    this.centerMap();
  }

  private initializeMap() {
    const baseMapURl = 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}'
    this.map = L.map('map');

  
    var tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution: '&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    tileLayer.addTo(this.map);
  }


  private addMarkers() {
    var locationMarker = L.marker([this.location.latitude, this.location.longitude]);
    locationMarker.addTo(this.map);
  }

  private centerMap() {
    var locationMarker = L.marker([this.location.latitude, this.location.longitude]);
    const bounds = L.latLngBounds([locationMarker.getLatLng()]);
    
    // Fit the map view to the bounds
    this.map.fitBounds(bounds);
  }
}
