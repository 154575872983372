import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FileService } from '../../services/file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../../const/urls';
import { AlertService } from '../../services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'upload-files-component',
  templateUrl: './upload-files.component.html',
})
export class UploadFilesComponent implements OnInit {
  selectedFiles: FileList;
  publicFileUrl: String;
  fileuploadSuccessful = false;
  progress: { percentage: number } = { percentage: 0 };
  message = '';
  @Output() postImageUrl = new EventEmitter();

  fileInfos: Observable<any>;

  constructor(
    private fileService: FileService,
    private alertService: AlertService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.fileInfos = this.fileService.getUploadedFiles();
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  upload() {
    this.progress.percentage = 0;

    this.fileService.upload(this.selectedFiles).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress.percentage = Math.round(
          (100 * event.loaded) / event.total
        );
      } else if (event instanceof HttpResponse) {
        this.alertService.success('Bild erfolgreich Hochgeladen');

        const fileName = JSON.parse(event.body as string).fileUrl;
        const fileUrl = serverUrl + '/api/files/' + fileName;
        this.publicFileUrl = fileUrl;
        this.postImageUrl.emit(fileUrl);
        this.fileuploadSuccessful = true;
      } else {
        this.resetFileinput();
        this.alertService.error('Fehler beim Imageupload');
      }
    }, (err) => {
      this.resetFileinput();
      this.alertService.error('Fehler beim Imageupload' + err.error);
    });
  }

  resetFileinput() {
    this.selectedFiles = undefined;
    this.fileuploadSuccessful = false;
    this.publicFileUrl = '';
  }
}
