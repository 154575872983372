import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { GroupDTO, MemberDTO, MessageDTO } from '../../dto/GroupDTO';
import { GroupService } from '../../services/group.service';
import { UserDTO } from '../../dto/UserDTO';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { UserService } from '../../services/user.service';

export interface GroupDialogData {
  group: GroupDTO;
  editMode: boolean;
}

@Component({
  selector: 'group-dialog',
  templateUrl: './group-dialog.component.html',
  styleUrls: ['./group-dialog.component.css']
})
export class GroupDialogComponent implements OnInit {
  editMode = false;
  members: MemberDTO[] = [];
  messages: MessageDTO[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GroupDialogData,
    public dialog: MatDialog,
    private groupService: GroupService,
    private userService: UserService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.editMode = this.data.editMode;

    this.groupService.findById(this.data.group.id).subscribe((res) => {
      this.members = res.groupMembers;
      this.messages = res.groupMessages;
    });
  }

  editDialog() {
    this.editMode = !this.editMode;
  }

  saveDialog() {
    console.log(this.data.group);
    this.groupService.update(this.data.group, this.data.group.id).subscribe((res) => {
      this.editMode = !this.editMode;
      this.dialog.closeAll();
    });
  }

  openUser(member: MemberDTO) {
    this.userService.getUserById(member.userId)
      .subscribe(user => {
        const dialogRef = this.dialog.open(UserDialogComponent, {
          data: {
            user: user
          },
          width: window.innerWidth < 991 ? '100vw' : '50vw',
        });
    
        dialogRef.afterClosed().subscribe(closed => {
        })
      })
  }
}
