import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { AuthImagePipe } from './basic.pipe';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        RouterModule,
        CommonModule,
        NgbModule,
        JwBootstrapSwitchNg2Module
      ],
    providers: [],
    declarations: [
        AuthImagePipe,
    ],
    exports: [
        AuthImagePipe,
    ],
})
export class PipeModule { }
