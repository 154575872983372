import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dateFormat from 'dateformat';

import { DomSanitizer } from '@angular/platform-browser';
import { GroupService } from '../../services/group.service';
import { UserDTO } from '../../dto/UserDTO';
import { PostService } from '../../services/post.service';
import { CommentDTO } from '../../dto/CommentDTO';
import { UserActivityService } from '../../services/user-activity.service';
import { UserActivityDTO } from '../../dto/UserActivityDTO';
import { GroupDTO, MessageDTO } from '../../dto/GroupDTO';
import { RatingService } from '../../services/rating.service';
import { ProductRatingDTO, VeganMapLocationRatingDTO } from '../../dto/RatingDTO';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import { LocationDialogComponent } from '../location-dialog/location-dialog.component';

export interface UserDialogData {
  user: UserDTO;
}

@Component({
  selector: 'user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent implements OnInit {
  editMode = false;
  comments: CommentDTO[] = [];
  activities: UserActivityDTO[] = [];
  groups: GroupDTO[] = [];
  messages: MessageDTO[] = [];
  locationRatings: VeganMapLocationRatingDTO[] = [];
  productRatings: ProductRatingDTO[] = [];
  showCompleteComments = false
  showCompleteMessage = false
  userActivityStatus = "Gar nicht"
  userActivityNumber = 0
  ratingAmount = 0;
  public userActivityData: Array<any>;
  public userActivityLabels: Array<any>;
  public userActivityColors: Array<any>;
  public userActivityOptions: any;  
  public userActivityType;
  public userAcitivityCanvas: any;
  public userAcitivityCtx;
  public userAcitivityGradientStroke;
  public chartColor;
  public userAcitivityGradientFill;
  public userAcitivityGradientChartOptionsConfiguration: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    public dialog: MatDialog,
    private groupService: GroupService,
    private userActivityService: UserActivityService,
    private postService: PostService,
    private ratingService: RatingService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.createChartOptions();

    this.postService.getCommentsForUserId(this.data.user.userId).subscribe(comments => {
      this.comments = comments;
    });

    this.userActivityService.findAllByUserId(this.data.user.userId).subscribe(activities => {
      this.createActiveDevicesChart(activities);
      this.activities = activities

      var differentDates = [];
      activities.forEach(entry => {
        var entryDate = new Date(entry.date);
        entryDate.setHours(0,0,0,0);
        if(!differentDates.includes(entryDate.toISOString())) {
          differentDates.push(entryDate.toISOString());
        }
      })
      this.userActivityNumber = differentDates.length

      if(this.userActivityNumber > 0) {
        this.userActivityStatus = "Kaum"
      } else if(this.userActivityNumber > 10) {
        this.userActivityStatus = "Bisschen"
      } else if(this.userActivityNumber > 30) {
          this.userActivityStatus = "Aktiv"
      } else if(this.userActivityNumber > 30) {
         this.userActivityStatus = "Aktiv"
      }else if(this.userActivityNumber > 100) {
        this.userActivityStatus = "Sehr Aktiv"
    }

    })
    this.groupService.findAllFromUserId(this.data.user.userId).subscribe((res) => {
      this.groups = res.results;
    });

    this.groupService.findAllGroupMessagesFromUserId(this.data.user.userId).subscribe((res) => {
      this.messages = res;
    });

    this.ratingService.getLocationRatingsFromUser(this.data.user.userId).subscribe((res) => {
      this.locationRatings = res.results;
      this.ratingAmount += res.results.length;
    })

    this.ratingService.getProductRatingsFromUser(this.data.user.userId).subscribe((res) => {
      this.productRatings = res.results;
      this.ratingAmount += res.results.length;
    })
  }

  private createActiveDevicesChart(data: UserActivityDTO[]) {
    data = data.filter(e => {
      var notNull = e != undefined;
      var isOpenApp = e.userActivityType == "OPEN_APP";

      return notNull && isOpenApp;
    }).sort((a: UserActivityDTO, b: UserActivityDTO) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    const progress = data, hash = Object.create(null), grouped = [];

    progress.forEach(function (a) {
        const key = dateFormat(a.date, 'dd.mm.yy');
        const parsedKey = key;// < 10 ? '0' + key : key;
        if (!hash[parsedKey]) {
            hash[parsedKey] = { amount: 0, time: parsedKey };
            grouped.push(hash[parsedKey]);
        }
        hash[parsedKey].amount += 1;
    });

    this.userAcitivityCanvas = document.getElementById('userActivityChart');
    this.userAcitivityCtx = this.userAcitivityCanvas.getContext('2d');

    this.userAcitivityGradientStroke = this.userAcitivityCtx.createLinearGradient(500, 0, 100, 0);
    this.userAcitivityGradientStroke.addColorStop(0, '#80b6f4');
    this.userAcitivityGradientStroke.addColorStop(1, this.chartColor);

    this.userAcitivityGradientFill = this.userAcitivityCtx.createLinearGradient(0, 170, 0, 50);
    this.userAcitivityGradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
    this.userAcitivityGradientFill.addColorStop(1, 'rgba(249, 99, 59, 0.40)');

    this.userActivityData = [
      {
        label: 'Aktive Geräte',
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: grouped.map(d => d.amount)
      }
    ];
    this.userActivityColors = [
      {
        borderColor: '#f96332',
        pointBorderColor: '#FFF',
        pointBackgroundColor: '#f96332',
        backgroundColor: this.userAcitivityGradientFill
      }
    ];
    this.userActivityLabels = grouped.map(r => r.time);
    this.userActivityOptions = this.userAcitivityGradientChartOptionsConfiguration;

    this.userActivityType = 'line';
  }

  getSystemIcon(activity: UserActivityDTO) {
    if (activity.deviceSystem == 'ANDROID') {
      return '/assets/img/android.png';
    } else {
      return '/assets/img/apple.png';
    }
  }

  getLanguageIcon(language: string) {
    switch (language) {
      case 'DE':
        return '/assets/img/germany.png';
      case 'EN':
        return '/assets/img/united-kingdom.png';
      default:
        return '/assets/img/germany.png';
    }
  }

  chartHovered($event: any) {
    throw new Error('Method not implemented.');
    }

    chartClicked($event: any) {
    throw new Error('Method not implemented.');
    }

  createChartOptions() {
    this.chartColor = '#FFFFFF';
    this.userAcitivityGradientChartOptionsConfiguration = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        bodySpacing: 4,
        mode: 'nearest',
        intersect: 1,
        position: 'nearest',
        xPadding: 10,
        yPadding: 10,
        caretPadding: 0
      },
      responsive: 1,
      scales: {
        yAxes: [{
          display: 1,
          ticks: {
            display: false,
            beginAtZero: true
          },
          gridLines: {
            zeroLineColor: 'transparent',
            drawTicks: false,
            display: false,
            drawBorder: false
          }
        }],
        xAxes: [{
          display: 1,
          ticks: {
            display: true
          },
          gridLines: {
            zeroLineColor: 'transparent',
            drawTicks: true,
            display: true,
            drawBorder: true
          }
        }]
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 15,
          bottom: 15
        }
      }
    };
  }

  openProductInfo(product: ProductRatingDTO) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: {
        product: product,
      },
      width: window.innerWidth < 991 ? '100%' : '50%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openLocationRating(location: VeganMapLocationRatingDTO) {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      data: {
        location: location,
      },
      width: window.innerWidth < 991 ? '100%' : '50%',
    });
  }
}
