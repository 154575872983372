import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserActivityDTO } from '../dto/UserActivityDTO';

@Injectable({
  providedIn: 'root',
})
export class OpenFoodFactService {
  constructor(private client: HttpClient) {}

  getProductByEan(ean: string): Observable<OpenFoodFactResponse> {
    const href = `https://world.openfoodfacts.org/api/v0/product/${ean}.json`;

    return this.client.get<OpenFoodFactResponse>(href);
  }
}

type OpenFoodFactResponse = { 
  [name: string]: any 
};