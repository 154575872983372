import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { OpenFoodFactService } from '../../services/open-food-fact.service';
import { ProductRatingDTO } from '../../dto/RatingDTO';

export interface GroupDialogData {
  product: ProductRatingDTO;
}

type OpenFoodFactResponse = { 
  [name: string]: any 
};

@Component({
  selector: 'product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.css']
})
export class ProductDialogComponent implements OnInit {
  editMode = false;
  product: OpenFoodFactResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GroupDialogData,
    public dialog: MatDialog,
    private openFoodFactService: OpenFoodFactService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.openFoodFactService.getProductByEan(this.data.product.productId).subscribe(res => {
      this.product = res['product'];
    })
  }

  editDialog() {
    this.editMode = !this.editMode;
  }
}
