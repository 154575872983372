import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../const/urls';
import { Sort } from '@angular/material/sort';
import { GroupDTO, MessageDTO } from '../dto/GroupDTO';
import { GroupList } from '../dto/GroupListDTO';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private client: HttpClient) {}

  getGroups(page: number, size: number, sort: Sort): Observable<GroupList> {
    const href = serverUrl + '/api/all-groups';
    const requestUrl = `${href}?sort=${sort.active},${sort.direction}&page=${page}&size=${size}`;

    return this.client.get<GroupList>(requestUrl);
  }

  findById(id: number): Observable<GroupDTO> {
    const href = serverUrl + '/api/groups/';
    const requestUrl = `${href}${id}`;

    return this.client.get<GroupDTO>(requestUrl);
  }

  findAllFromUserId(userId: string): Observable<GroupList> {
    const requestUrl = `${serverUrl}/api/groups?userId=${userId}`;

    return this.client.get<GroupList>(requestUrl);
  }

  findAllGroupMessagesFromUserId(userId: string): Observable<MessageDTO[]>{
    const requestUrl = `${serverUrl}/api/users/${userId}/group-messages`;

    return this.client.get<MessageDTO[]>(requestUrl);


  }

  create(newGroup: GroupDTO): Observable<GroupDTO> {
    const url = serverUrl + '/api/groups';

    return this.client.post<GroupDTO>(url, newGroup);
  }

  update(newGroup: GroupDTO, id: number): Observable<GroupDTO> {
    const href = serverUrl + '/api/groups/';
    const requestUrl = `${href}${id}`;

    return this.client.put<GroupDTO>(requestUrl, newGroup);
  }

  delete(id: number): Observable<GroupDTO> {
    const href = serverUrl + '/api/groups/';
    const requestUrl = `${href}${id}?userId=`;

    return this.client.delete<GroupDTO>(requestUrl);
  }
}
