import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../const/urls';
import { Post } from '../dto/Post';
import { PostList } from '../dto/PostList';
import { CreatePost } from '../dto/CreatePost';
import { Sort } from '@angular/material/sort';
import { ServiceType } from "../dto/ServiceType";
import { CommentDTO } from '../dto/CommentDTO';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private client: HttpClient) {}

  delete(id: number) {
    const href = serverUrl + '/api/posts/';
    const requestUrl = `${href}${id}`;

    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + sessionStorage.getItem('token'),
      'Content-Type': 'application/json; charset=utf-8',
    });

    const options = { headers: headers };

    return this.client.delete<Post>(requestUrl, options);
  }

  getPosts(page: number, size: number, sort: Sort): Observable<PostList> {
    const href = serverUrl + '/api/all-posts';
    const requestUrl = `${href}?sort=${sort.active},${sort.direction}&page=${page}&size=${size}`;

    return this.client.get<PostList>(requestUrl);
  }

  create(newPost: CreatePost): Observable<Post> {
    const url = serverUrl + '/api/posts';

    return this.client.post<Post>(url, newPost);
  }

  update(newPost: CreatePost, id: number): Observable<Post> {
    const href = serverUrl + '/api/posts/';
    const requestUrl = `${href}${id}`;

    return this.client.post<Post>(requestUrl, newPost);
  }

  getCommentsForPost(id: number): Observable<CommentDTO[]> {
    const href = serverUrl + '/api/posts/' + id + '/comments';

    return this.client.get<CommentDTO[]>(href);
  }

  postOnInstagram(id: number): Observable<boolean> {
    const href = serverUrl + '/api/posts/' + id + '/notification';

    return this.client.post<boolean>(href, ServiceType.INSTAGRAM);
  }

  postOnTwitter(id: number): Observable<boolean> {
    const href = serverUrl + '/api/posts/' + id + '/notification';

    return this.client.post<boolean>(href, ServiceType.TWITTER);
  }

  getCommentsForUserId(id: string): Observable<CommentDTO[]> {
    const href = serverUrl + '/api/user/' + id + '/comments';

    return this.client.get<CommentDTO[]>(href);
  }
}
