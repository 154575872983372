import { Component, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent {
  constructor(public dialog: MatDialog) {}
  deleteCal = new EventEmitter<string>();

  closeDialog() {
    this.dialog.closeAll();
  }

  deleteCallback() {
    this.deleteCal.emit();
    this.dialog.closeAll();
  }
}
