import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { UploadFilesComponent } from './fileupload/upload-files.component';
import { PostDialogComponent } from './post-dialog/post-dialog.component';
import { AngularMaterialModule } from '../angular-material.module';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PipeModule } from '../pipes/pipe.module';
import { GroupDialogComponent } from './group-dialog/group-dialog.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { ChartsModule } from 'ng2-charts';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { LocationDialogComponent } from './location-dialog/location-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    RouterModule,
    LMarkdownEditorModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    AngularMaterialModule,
    PipeModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    DeleteDialogComponent,
    UploadFilesComponent,
    PostDialogComponent,
    UserDialogComponent,
    ProductDialogComponent,
    LocationDialogComponent,
    GroupDialogComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    DeleteDialogComponent,
    UploadFilesComponent,
    PostDialogComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }
