import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../const/urls';
import { UserActivityDTO } from '../dto/UserActivityDTO';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  constructor(private client: HttpClient) {}

  findAllOpenApp(amountOfDays: number): Observable<UserActivityDTO[]> {
    const href = serverUrl + `/api/user-activity/OPEN_APP?amountOfDays=${amountOfDays}`;

    return this.client.get<UserActivityDTO[]>(href);
  }

  findAllByUserId(userId: string): Observable<UserActivityDTO[]> {
    const href = serverUrl + `/api/user/${userId}/user-activity`;

    return this.client.get<UserActivityDTO[]>(href);
  }
}
