import { Component, OnInit } from '@angular/core';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'business_chart-bar-32', class: '' },
    { path: '/post-list', title: 'Posts',  icon: 'text_align-center', class: '' },
    { path: '/users', title: 'Benutzer',  icon: 'business_badge', class: '' },
    { path: '/groups', title: 'Gruppen',  icon: 'shopping_shop', class: '' },
    { path: '/ratings', title: 'Bewertungen',  icon: 'ui-2_favourite-28', class: '' },
    { path: '/images', title: 'Images',  icon: 'design_image', class: '' },    
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  }
}
