import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    // sessionStorage.setItem(
    //   "token",
    //   btoa("admin:p2MDcdX6BnxbS8Ew")
    // );
  }

}
