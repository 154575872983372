import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../const/urls';
import { FileDTO } from '../dto/FileDTO';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private client: HttpClient) {}

  getUploadedFiles(): Observable<FileDTO[]> {
    return this.client.get<FileDTO[]>(`${serverUrl}/api/files`);
  }

  upload(data: FileList): Observable<HttpEvent<{}>> {
    const formData = new FormData();

    Array.from(data).forEach((file) => {
      formData.append('file', file);
    });

    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + sessionStorage.getItem('token'),
    });

    const request = new HttpRequest(
      'POST',
      `${serverUrl}/api/files/upload`,
      formData,
      {
        reportProgress: true,
        responseType: 'text',
        headers: headers,
      }
    );

    return this.client.request(request);
  }

  getImage(imageUrl: string): Observable<File> {
    return this.client.get<File>(imageUrl);
  }

  deleteImage(imageName: string): Observable<void> {
    const url = `${serverUrl}/api/files/${imageName}`;

    return this.client.delete<void>(url);
  }
}
