import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from '../const/urls';
import { UserDTO } from '../dto/UserDTO';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private client: HttpClient) {}

  deleteDevice(id: string) {
    const href = serverUrl + '/api/vegan_stats/';
    const requestUrl = `${href}${id}`;

    return this.client.delete<boolean>(requestUrl);
  }

  getDevices(page: number, size: number, sort: Sort): Observable<UserDTO[]> {
    const href = serverUrl + '/api/vegan_stats';
    const requestUrl = `${href}?sort=${sort.active},${sort.direction}&page=${page}&size=${size}`;

    return this.client.get<UserDTO[]>(requestUrl);
  }

  getUserById(userId: string): Observable<UserDTO> {
    const href = serverUrl + '/api/vegan_stats';
    const requestUrl = `${href}/${userId}`;

    return this.client.get<UserDTO>(requestUrl);
  }
}
